import * as  Yup from "yup"


export const registerValidations = Yup.object({
    email: Yup.string().email("invalid email address").required('Please enter your email address'),
    password: Yup.string().required('please enter your password').matches(
        /^(?=.{6,})/,
        "Must Contain 6 Characters"),
    confirmPassword: Yup.string().required('please retype your password.').oneOf([Yup.ref('password')], 'your passwords does not match.'),
    register_checkbox: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions')
    // curPassword: Yup.string().required('please enter your current password')
})
export const loginValidations = Yup.object({
    email: Yup.string().email("invalid email address").required('Please enter your email address'),
    password: Yup.string().required('please enter your password')
    // curPassword: Yup.string().required('please enter your current password')
})

// For NewsFeed Modal Form
export const createProfileValidations = Yup.object({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('please enter your last name')
})