

import React, { useEffect, useState } from "react";
import { Form, Link, NavLink, useNavigate } from "react-router-dom";
import FloatingLabelInput from "./FloatingLabelInput"; // Adjust the import path
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { SignInImages } from "../assets/Images/ImagesLink";
// import toast from "react-hot-toast";
import axios, { PublicKey } from "../API/axios";
import fetch_axios from "axios";
import { useFormik } from "formik";
import { loginValidations } from "./Validations";
import { useGoogleLogin } from "@react-oauth/google";
import "../assets/css/SignIn.css";
import LeftNavigation from "./Navigation/LeftNavigation";
import SuccessToast from "./SuccessToast";
import WarningToast from "./WarningToast";
import ErrorToast from "./ErrorToast";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyD8GJvS-To2H4dOM8lbn5JRUQVhCL8uxJM",
  authDomain: "welinkjobs-23583.firebaseapp.com",
  projectId: "welinkjobs-23583",
  storageBucket: "welinkjobs-23583.appspot.com",
  messagingSenderId: "375220227786",
  appId: "1:375220227786:web:bb1fd63622ce66d4936e33",
  measurementId: "G-VY0LG9HEKF",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const SignIn = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState("false");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: localStorage.getItem("user-email") || "",
    password: "",
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: loginValidations,
      onSubmit: async () => {
        setLoading(true);
        const RegisterData = {
          deviceType: "Web",
          email: values.email,
          password: values.password,
        };
        try {
          const res = await axios.post("OAuth/LoginWithEmail", RegisterData, {
            headers: {
              PublicKey,
            },
          });
          console.log("login", res.data.responseData);
          const response = res.data.responseData.profile;
          const userRole = res.data.responseData.userRoles;
          setToastMessage(res.data.message);
          setShowToast(true);
          localStorage.setItem("user-Id", response.id);
          localStorage.setItem("userUid", response.userUid);
          localStorage.setItem("user-email", response.email);
          localStorage.setItem("user-nameTag", response.nameTag);
          localStorage.setItem("user-profilPic", response.profilPic);
          localStorage.setItem("user-referCode", response.referCode);
          localStorage.setItem("sessionId", response.sessionId);
          localStorage.setItem("token", response.token);
          localStorage.setItem("loginType", response.loginType);
          localStorage.setItem("profilPic", response.profilPic);
          localStorage.setItem("firstName", response.firstName);
          localStorage.setItem("lastName", response.lastName);
          localStorage.setItem("userRole", userRole);

          localStorage.removeItem("guestMode");
          setTimeout(() => {
            navigate("/NewsFeed");
          }, 1000);
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setWarningToastMessage(error.response.data.message);
            setShowWarningToast(true);
          } else {
            setErrorToastMessage(error.response.data.message);
            setShowErrorToast(true);
            setTimeout(() => {
              if (error.response.data.message === "Account not confirmed!") {
                navigate("/ConfirmAccount");
              }
            }, 2000);
          }
        } finally {
          setLoading(false);
        }
      },
    });

  const externalLogin = async (responseData) => {
    try {
      const res = await axios.post(
        "OAuth/LoginWithGoogle",
        responseData,
        {
          headers: {
            PublicKey,
          },
        }
      );
      console.log("login", res.data.responseData);
      const response = res.data.responseData.profile;
      const userRole = res.data.responseData.userRoles;
      // toast.success("Login successful!");
      setToastMessage(res.data.message);
      setShowToast(true);
      localStorage.setItem("user-Id", response.id);
      localStorage.setItem("userUid", response.userUid);
      localStorage.setItem("user-email", response.email);
      localStorage.setItem("user-nameTag", response.nameTag);
      localStorage.setItem("user-profilPic", response.profilPic);
      localStorage.setItem("user-referCode", response.referCode);
      localStorage.setItem("sessionId", response.sessionId);
      localStorage.setItem("token", response.token);
      localStorage.setItem("loginType", response.loginType);
      localStorage.setItem("profilPic", response.profilPic);
      localStorage.setItem("firstName", response.firstName);
      localStorage.setItem("lastName", response.lastName);
      localStorage.setItem("userRole", userRole);
      navigate("/NewsFeed");
      // localStorage.setItem("guestMode", "false");
      localStorage.removeItem("guestMode");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setWarningToastMessage(error.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(error.response.data.message);
        setShowErrorToast(true);
        setTimeout(() => {
          if (error.response.data.message === "Account not confirmed!") {
            navigate("/ConfirmAccount");
          }
        }, 2000);
      }
    }
  };

  const googleSignin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const resp = await fetch_axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        const responseData = {
          deviceType: "Web",
          email: resp.data.email,
          externalLoginId: resp.data.sub,
          loginType: "Google",
        };
        externalLogin(responseData);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [guestMode, setGuestMode] = useState(false);
  const activateGuestMode = () => {
    setGuestMode(true);
    navigate("/NewsFeed");
    localStorage.setItem("guestMode", "true");
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [originalemail, setOriginalEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [externalEmail, setExernalEmail] = useState("");

  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const _loginWithApple = async (accessToken) => {
    try {
      const responseData = {
        deviceType: "Web",
        appleAccessToken: accessToken,
        loginType: "Apple",
      };
      const res = await axios.post(
        "OAuth/LoginWithApple",
        responseData,
        {
          headers: {
            PublicKey,
          },
        }
      );

      console.log("login", res.data.responseData);
      const response = res.data.responseData.profile;
      const userRole = res.data.responseData.userRoles;
      setToastMessage(res.data.message);
      setShowToast(true);
      localStorage.setItem("user-Id", response.id);
      localStorage.setItem("userUid", response.userUid);
      localStorage.setItem("user-email", response.email);
      localStorage.setItem("user-nameTag", response.nameTag);
      localStorage.setItem("user-profilPic", response.profilPic);
      localStorage.setItem("user-referCode", response.referCode);
      localStorage.setItem("sessionId", response.sessionId);
      localStorage.setItem("token", response.token);
      localStorage.setItem("loginType", response.loginType);
      localStorage.setItem("profilPic", response.profilPic);
      localStorage.setItem("firstName", response.firstName);
      localStorage.setItem("lastName", response.lastName);
      localStorage.setItem("userRole", userRole);
      navigate("/NewsFeed");
      // localStorage.setItem("guestMode", "false");
      localStorage.removeItem("guestMode");
    } catch (error) {
      console.error("Error during registration:", error);
      setErrorToastMessage(error.response.data.message);
      setShowErrorToast(true);
    }
  };
  const signInWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log(user.accessToken);
      setFirstName(user.displayName?.split(" ")[0]);
      setLastName(user.displayName?.split(" ")[1]);
      setExernalEmail(user.email);
      setAccessToken(user.accessToken);
      setPhotoUrl(user.photoURL);
      _loginWithApple(user.accessToken)

    } catch (error) {
      console.error("Error signing in with Apple:", error);
    }
  };

  const [ConnectionListForGuest, setConnectionListForGuest] = useState("");
  const ConnectionListAllForGuest = async (id) => {
    try {
      await axios
        .get("UserFollow/GetMostFollowingUser?DeviceType=Web")
        .then((resData) => {
          // console.log("ConnectionListAllForGuest", resData.data.responseData);
          setConnectionListForGuest(resData.data.responseData);
        });
    } catch (error) {
      console.log(error);
    }
  };
  // console.log('ConnectionListForGuest', ConnectionListForGuest);

  useEffect(() => {
    ConnectionListAllForGuest();
  }, []);

  const LinktoGuest = () => {
    setGuestMode(true);
    localStorage.setItem("guestMode", "true");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 full-height p-2 loginImage">
          <div className="bg-cover">
            <div className="imageData">
              <div className="text-center">
                <img src={SignInImages.whiteLogo} alt="" />
              </div>
              <p>
                Stay connected for{" "}
                <NavLink to={`/SearchJob`} onClick={LinktoGuest} className="border-bottom">
                  Jobs
                </NavLink>{" "}
                &{" "}
                <NavLink to={`/NewsFeed`} onClick={LinktoGuest} className="border-bottom">
                  More
                </NavLink>
              </p>
              <div className="userLink text-center mt-2  justify-content-center">
                <Link to='/People' onClick={LinktoGuest}>
                  <div className="circle-container">
                    {ConnectionListForGuest && ConnectionListForGuest?.slice(0, 3).map((user, userIndex) => (
                      <img
                        src={user?.profilPic}
                        alt={`Circle ${userIndex + 1}`}
                        className="circle-img"
                        key={userIndex}
                      />
                    ))}
                  </div>
                </Link>
                <span className="f-14 fw-bold">12k+ people joined us, now it's your turn</span>
                {/* <img src={SignInImages.ovalwithtext} alt="" width="80%" /> */}
              </div>
            </div>
            <div className="linksHelp">
              <p>
                <a href="/privacy-policy.html" target='_blank'>Privacy Policy</a> |{" "}
                <a href="/terms-and-conditions.html" target='_blank'>Terms and Condition</a>
              </p>
              <p>
                Having Troubles?{" "}
                <a href="/faq.html" className="border-bottom me-3" target='_blank'>
                  Get Help
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="form-container pb-3">
            <Form onSubmit={handleSubmit}>
              <h2 className="header">Sign in</h2>
              <p className="paraClass1">
                New User? <Link to="/SignUp">Create an account</Link>
              </p>
              <FloatingLabelInput
                id="email"
                label="Email address"
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="custom-input"
              />
              {errors.email && touched.email && (
                <p className="form-error" style={{ color: "red" }}>
                  {errors.email}
                </p>
              )}
              <div className="password-input border-signIn d-flex align-items-baseline">
                <FloatingLabelInput
                  id="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              {errors.password && touched.password && (
                <p className="form-error" style={{ color: "red" }}>
                  {errors.password}
                </p>
              )}
              <div className="signup">
                <Link to="/ForgotPassword">Forgot Password</Link>
                <button
                  type="submit"
                  className="mainBtn"
                  disabled={!values.email || !values.password}
                >
                  {loading ? "Loading..." : "Sign In"}
                </button>
              </div>
              {/* <div className="my-1 text-center">
                <button
                  type="submit"
                  className="mainBtn"
                  disabled={!values.email || !values.password || loading}
                >
                  {loading ? "Loading..." : "Sign In"}
                </button>
              </div> */}
            </Form>
            <div className="divider">or</div>
            <div className="socialLogin d-flex justify-content-between">
              <button className="socialBtn" onClick={googleSignin}>
                <img src={SignInImages.google} alt="google" />
                <p>Signin with Google</p>
              </button>
              {/* <button className="socialBtn">
                <img src={SignInImages.facebook} alt="facebook" />
                <p>Facebook</p>
              </button> */}
              {/* <button className="socialBtn" onClick={handleAppleSignIn}>
                                <img src={SignInImages.icloud} alt="icloud" />
                                <p>iCloud</p>
                            </button> */}
              <button className="socialBtn" onClick={signInWithApple}>
                <img src={SignInImages.apple} alt="google" width={20} />
                <p>Signin with Apple</p>
              </button>
            </div>
            <div className="divider my-1">or</div>
            <div className="text-center">
              <button
                type="submit"
                className="mainBtn"
                onClick={activateGuestMode}
              >
                Explore as <span>Guest Mode</span>{" "}
              </button>
            </div>
            <div className="divider my-3">Apps</div>
            <div className="socialLogin d-flex justify-content-evenly pt-2">
              {/* <img src="https://welinkjobs.com/Images/Default%20Images/bit.ly_welinkjobsapp.png"
                // style={{height: 103px;width: 103px; margin-left: 5px }}
                style={{ height: '103px', width: '103px' }}
                alt="iOS App" /> */}
              <a href="https://bit.ly/welinkjobsapp" target="_blank">
                <img src={SignInImages.playstore} alt="" width="150px" />
              </a>
              <a href="https://bit.ly/welinkjobs-iOS" target="_blank">
                <img src={SignInImages.appstore} alt="" width="150px" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <LeftNavigation data={data} /> */}

      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />

      {showModal && (
        <div
          className="modal fade show modal-md"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="setting-header d-flex justify-content-between align-items-center p-2">
                <h6>Additional Details</h6>
                {/* <span
                  className="material-symbols-sharp me-3 pointer"
                  onClick={handleModalClose}
                >
                  close
                </span> */}
                <CloseSharpIcon className="material-symbols-sharp me-3 pointer"
                  onClick={handleModalClose}
                />
              </div>
              <Form>
                <div className="row modal-p settings CreateCompany">
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      Email
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <input
                      type="text"
                      value={originalemail}
                      onChange={(e) => setOriginalEmail(e.target.value)}
                    // invalid={display && email === ""}
                    />
                    {/* {display && !email ? (
          <span className="error_msg_lbl">Enter Email </span>
        ) : null} */}
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      FirstName
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    // invalid={display && firstName === ""}
                    />
                    {/* {display && !firstName ? (
          <span className="error_msg_lbl">Enter FirstName </span>
        ) : null} */}
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="about">
                      LastName
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    // invalid={display && lastName === ""}
                    />
                    {/* {display && !lastName ? (
          <span className="error_msg_lbl">Enter LastName </span>
        ) : null} */}
                  </div>

                  <div className="text-end mt-4">
                    <button
                      type="button"
                      className="closebtn me-2"
                      onClick={handleModalClose}
                      style={{ width: "60px" }}
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="mainBtn1"
                      // onClick={_registerWithApple}
                      style={{ width: "70px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignIn;
