import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
} from "react-bootstrap";
import { NewsFeed } from "../../assets/Images/ImagesLink";
import { toast } from "react-hot-toast";
import { Form, Link } from "react-router-dom";
import axios from "../../API/axios";
import Select from "react-select";
import SuccessToast from "../SuccessToast";
import WarningToast from "../WarningToast";
import ErrorToast from "../ErrorToast";
import pin from "../../assets/Images/svg/pin.svg";
import PostUpdate from "./PostUpdate";
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import ReportGmailerrorredSharpIcon from '@mui/icons-material/ReportGmailerrorredSharp';
import PersonOffSharpIcon from '@mui/icons-material/PersonOffSharp';
import UpdateSharpIcon from '@mui/icons-material/UpdateSharp';
import HideSourceSharpIcon from '@mui/icons-material/HideSourceSharp';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import ImageSharpIcon from '@mui/icons-material/ImageSharp';
const PostInteractionModal = ({
  postId,
  AllPostList,
  postList,
  setPage,
  setPostList,
  page,
  setTotalPages,
  totalPages,
  item,
  postOriginId,
  PostOriginType,
  setIsfollowed,
  isfollowed,
  ispinned
  // setShowDeletePost,
  // showDeletePost

}) => {
  console.log('postIdnewww', postId, item);
  const [showDeletePost, setShowDeletePost] = useState(false)


  useEffect(() => {
    if (showDeletePost) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showDeletePost])
  const sessionId = localStorage.getItem("sessionId");
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [updateContent, setUpdateContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const inputFileRef = useRef(null);
  const userUid = localStorage.getItem("userUid");

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [warningToastMessage, setWarningToastMessage] = useState("");
  const [showWarningToast, setShowWarningToast] = useState(false);

  const [ErrorToastMessage, setErrorToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  console.log('openModal', openModal);
  const closeModal = () => {
    setShowModal(false);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  //************************Delete Post start  ************************//

  const handleDeleteClose = () => {
    setShowDeletePost(false);
  };

  const deletePost = async () => {
    console.log('postIdnewww delte', item, postId);

    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    console.log('postIdnewww content', responseData);

    try {
      await axios.post(`PostAction/DeletePost`, responseData).then((res) => {
        console.log('hgsdas', res);

        setToastMessage(res.data.message);
        setShowToast(true);
        setShowDeletePost(!showDeletePost);
        setTimeout(() => {
          setPostList((prevPostList) =>
            prevPostList.filter((post) => post.postId !== postId)
          );
          if ((postList.length - 1) % 10 === 0 && page === totalPages) {
            setTotalPages(totalPages - 1);
          }
          if (page <= totalPages) {
            AllPostList(page);
          }
        }, 2000);

      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Delete Post End  ************************//

  //************************Repost Post start  ************************//
  const [reportPost, setReportPost] = useState(false);
  const [remark, setRemark] = useState("");
  const handlereportPost = () => {
    setReportPost(false);
  };

  const ReportPost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
      remark: remark,
    };
    console.log('postIdnewww', responseData);

    // try {
    //   await axios.post(`PostAction/ReportPost`, responseData).then((res) => {
    //     setReportPost(!reportPost);
    //     setRemark("");
    //     // console.log("resData", res.data.message);
    //     // toast.success(res.data.message);
    //     setToastMessage(res.data.message);
    //     setShowToast(true);
    //     AllPostList();
    //   });
    // } catch (e) {
    //   if (e.response && e.response.status === 404) {
    //     setWarningToastMessage(e.response.data.message);
    //     setShowWarningToast(true);
    //   } else {
    //     setErrorToastMessage(e.response.data.message);
    //     setShowErrorToast(true);
    //   }
    // }
  };

  //************************Repost Post End  ************************//

  //************************Pin Post start  ************************//
  const [ispin, setIspin] = useState(ispinned)
  const PinPost = async () => {
    console.log("pinpost");
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
      postOriginType: PostOriginType,
      postOriginUID: postOriginId
    };
    try {
      await axios.post(`PostAction/PinPost`, responseData).then((res) => {
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
        setIspin(true)
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Pin Post End  ************************//

  //************************Pin Post start  ************************//
  const UnPinPost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/UnpinPost`, responseData).then((res) => {
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
        setIspin((prevIspin) => false);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Pin Post End  ************************//

  //************************Hide Post start  ************************//
  const [isHidden, setIshidden] = useState(false)
  const hidePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/HidePost`, responseData).then((res) => {
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
        setIshidden(true)
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************Hide Post End  ************************//

  //************************UnHide Post start  ************************//
  const UnhidePost = async () => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      postId: postId,
    };
    try {
      await axios.post(`PostAction/UnhidePost`, responseData).then((res) => {
        // toast.success(res.data.message);
        setToastMessage(res.data.message);
        setShowToast(true);
        AllPostList();
        setIshidden(false)
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************UnHide Post End  ************************//


  const [showPost, setShowPost] = useState(false);

  useEffect(() => {
    if (showPost && inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, [showPost]);


  const copyPost = async () => {
    const copyData = {
      deviceType: "Web",
      id: postId,
      type: 1,
    };
    try {
      await axios.post("SMFeatures/SaveShareURL", copyData).then((res) => {
        console.log('res', res);

        navigator.clipboard.writeText(res.data.responseData.value);
        // toast.success('Link Copied')
        setToastMessage(res.data.message);
        setShowToast(true);
      });
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };
  // console.log("item", item);


  const FollowUser = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: id,
    };
    try {
      await axios.post("UserFollow/FollowUser", data).then((resData) => {
        console.log("resDataaaaa", resData);
        setIsfollowed(true)
        setToastMessage(resData.data.message);
        setShowToast(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  const [showUnfollowModal, setShowUnfollowModal] = useState(false);
  const handleCloseUnfollowModal = () => setShowUnfollowModal(false);
  const handleShowunfollowModal = () => setShowUnfollowModal(true);
  const unFollowUser = async (id) => {
    const responseData = {
      deviceType: "Web",
      sessionId: sessionId,
      userUID: item?.userUid,
    };
    try {
      await axios.post(`UserFollow/UnfollowUser`, responseData).then((res) => {
        console.log("res", res);
        setIsfollowed(false)
        setToastMessage(res.data.message);
        setShowToast(true);
        handleCloseUnfollowModal()
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };



  //************************ Follow Company  ************************//

  const FollowCompany = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      uiD_ID: id,
    };
    try {
      await axios.post("Entity/Follow/Company", data).then((resData) => {
        // CompanyDetails();
        setIsfollowed(true)
        setToastMessage(resData.data.message);
        setShowToast(true);
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  //************************ UnFollow Company  ************************//

  const UnFollowCompany = async (id) => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      uiD_ID: item?.userUid,
    };
    try {
      await axios.post("Entity/Unfollow/Company", data).then((resData) => {
        // CompanyDetails();
        setToastMessage(resData.data.message);
        setShowToast(true);
        setIsfollowed(false)
        handleCloseUnfollowModal()
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setWarningToastMessage(e.response.data.message);
        setShowWarningToast(true);
      } else {
        setErrorToastMessage(e.response.data.message);
        setShowErrorToast(true);
      }
    }
  };

  useEffect(() => {
    if (reportPost || showUnfollowModal) {
      document.body.style.overflow = 'hidden';

    } else {
      document.body.style.overflow = 'unset';

    }
  }, [reportPost, showUnfollowModal]);


  return (
    <>
      <div className="d-flex">
        {ispin === true ? <img src={pin} width={20} /> : ''}
        {item?.isOwned === false ? <>
          {isfollowed === true || item?.isFollowedByYou === true ? '' : <div className='ms-auto'>
            <button className='mainBtn1 me-2' style={{ width: '90px', height: '30px' }} onClick={() => {
              item?.postOriginType === 'Company' ?
                FollowCompany(item?.userUid) :
                FollowUser(item?.userUid)
            }} >
              Follow</button>
          </div>}
        </> : ''}
        <div
          className="postMenu pointer"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <MoreHorizSharpIcon className="material-symbols-sharp" />
          {showDropdown && (
            <Dropdown.Menu
              show={showDropdown}
              ref={dropdownRef}
              className="InteractionModal"
            >
              {item?.isOwned === false && (
                <>
                  <Dropdown.Item as="button" className="dropdownList">
                    <Link
                      onClick={() => {
                        setReportPost(!reportPost);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <ReportGmailerrorredSharpIcon className="material-symbols-sharp me-2" />
                        <span className="dropdownTxt f-14">Report Post</span>
                      </div>
                      <p className="f-12 dropdownPara">
                        We won't let {item.userName} know who reported
                      </p>
                    </Link>
                  </Dropdown.Item>
                  {isfollowed === true || item?.isFollowedByYou === true ? <Dropdown.Item as="button" className="dropdownList">
                    <Link
                      onClick={() => {
                        handleShowunfollowModal()
                        toggleDropdown();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <PersonOffSharpIcon className="material-symbols-sharp me-2" />
                        <span className="dropdownTxt f-14">Unfollow</span>
                      </div>
                      <p className="f-12 dropdownPara">
                        Unfollow User
                      </p>
                    </Link>
                  </Dropdown.Item> : ''}

                </>

              )}

              {item?.isOwned === true && (
                <>
                  {ispin === true ?
                    <Dropdown.Item as="button" className="dropdownList" >
                      <Link
                        onClick={() => {
                          UnPinPost();
                          toggleDropdown();
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img src={pin} />
                          <span className="dropdownTxt f-14">Unpin</span>
                        </div>
                        <p className="f-12 dropdownPara">Unpin Post</p>
                      </Link>
                    </Dropdown.Item> :
                    <Dropdown.Item as="button" className="dropdownList" >
                      <Link
                        onClick={() => {
                          PinPost();
                          toggleDropdown();
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img src={pin} />
                          <span className="dropdownTxt f-14">Pin</span>
                        </div>
                        <p className="f-12 dropdownPara">Pin Post</p>
                      </Link>
                    </Dropdown.Item>
                  }

                  <Dropdown.Item as="button" className="dropdownList">
                    <Link
                      onClick={() => {
                        setShowDeletePost(!showDeletePost);
                        toggleDropdown();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <DeleteOutlineSharpIcon className="material-symbols-sharp me-2" />
                        <span className="dropdownTxt f-14">Delete</span>
                      </div>
                      <p className="f-12 dropdownPara">
                        Once Deleted it can't be recovered
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button" className="dropdownList d-block">
                    <Link onClick={() => {
                      openModal()
                    }}>
                      {/* <div className="update-post-cont"> */}
                      <div className="">
                        <UpdateSharpIcon className="material-symbols-sharp me-2" />
                        <span className="dropdownTxt f-14">Update Post</span>
                      </div>
                      <p className="f-12 dropdownPara">Update post</p>
                      {/* </div> */}
                    </Link>
                  </Dropdown.Item>
                </>
              )}




              <Dropdown.Item as="button" className="dropdownList">
                <Link
                  onClick={() => {
                    copyPost();
                    toggleDropdown();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <ContentCopySharpIcon className="material-symbols-sharp me-2" />
                    <span className="dropdownTxt f-14">Copy Link</span>
                  </div>
                  <p className="f-12 dropdownPara">Copy Post Link</p>
                </Link>
              </Dropdown.Item>
              {item?.isHidden === true || isHidden === true ? (
                <Dropdown.Item as="button" className="dropdownList">
                  <Link
                    onClick={() => {
                      UnhidePost();
                      toggleDropdown();
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <HideSourceSharpIcon className="material-symbols-sharp me-2" />
                      <span className="dropdownTxt f-14">UnHide Post</span>
                    </div>
                    <p className="f-12 dropdownPara">Unhide Post</p>
                  </Link>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item as="button" className="dropdownList">
                  <Link
                    onClick={() => {
                      hidePost();
                      toggleDropdown();
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <HideSourceSharpIcon className="material-symbols-sharp me-2" />
                      <span className="dropdownTxt f-14">Hide Post</span>
                    </div>
                    <p className="f-12 dropdownPara">Hide Post</p>
                  </Link>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          )}
        </div>
      </div>




      {showDeletePost && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                <h6> Are you sure you want to delete post?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleDeleteClose();
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <button
                  color=""
                  className="closebtn"
                  onClick={() => deletePost()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {reportPost && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                {/* <h4>Report Post</h4> */}
                <h6> Are you sure you want to Report this post?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handlereportPost();
                  }}
                />
              </div>

              <div className="lableStyle">
                <input
                  type="text"
                  placeholder="Enter feedback here"
                  required
                  value={remark}
                  style={{ width: "100%" }}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>

              <div className="col-md-12 text-end mt-4">
                <button type="button" className="mainBtn1" onClick={ReportPost}>
                  Report
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {showUnfollowModal && (
        <div
          className="modal fade show postAdd"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog  modal-dialog-centered " role="document">
            <div className="modal-content d-flex justify-content-around p-2">
              <div className="modalBorder d-flex justify-content-between align-items-center p-2">
                {/* <h6>Unfollow User</h6> */}
                <h6> Are you sure you want to unfollow user?</h6>
                <CloseSharpIcon
                  className="material-symbols-sharp me-2 pointer"
                  onClick={() => {
                    handleCloseUnfollowModal();
                  }}
                />
              </div>

              <div className="col-md-12 text-end mt-4 d-flex justify-content-center">
                <button
                  type="button"
                  className="mainBtn1"
                  onClick={() => { item?.postOriginType === 'Company' ? UnFollowCompany() : unFollowUser() }}
                >
                  Unfollow
                </button>
              </div>

            </div>
          </div>
        </div>
      )}
      {showModal && (
        <PostUpdate
          openModal={openModal}
          closeModal={closeModal}
          item={item}
          PostOriginType={PostOriginType}
          postOriginId={postOriginId}
          setShowModal={setShowModal}
          postId={postId}
          setPostList={setPostList}
          AllPostList={AllPostList}
        />)}


      <SuccessToast
        show={showToast}
        message={toastMessage}
        onClose={() => setShowToast(false)}
      />

      <WarningToast
        show={showWarningToast}
        message={warningToastMessage}
        onClose={() => setShowWarningToast(false)}
      />

      <ErrorToast
        show={showErrorToast}
        message={ErrorToastMessage}
        onClose={() => setShowErrorToast(false)}
      />
    </>
  );
};

export default PostInteractionModal;
