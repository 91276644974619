import { useEffect, useState } from "react";
import {
  Badge,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import axios from "../../API/axios";
import MessageList from "../Message/MessageList";
import { people } from "../../assets/Images/ImagesLink";
import pin from "../../assets/Images/svg/pin.svg";
import MessageInteraction from "../Message/MessageInteraction";
// import SmsSharpIcon from '@mui/icons-material/SmsSharp';
import TextsmsSharpIcon from '@mui/icons-material/TextsmsSharp';
import PushPinSharpIcon from '@mui/icons-material/PushPinSharp';

export default function TopMessage() {
  const sessionId = localStorage.getItem("sessionId");

  const [notifi, setNotifi] = useState([]);
  const [notifiCount, setNotifiCount] = useState("");
  const formattedTime = moment("aaa").fromNow();

  //   const sessionId = localStorage.getItem('sessionId')
  const { UserId } = useParams();
  const [msgId, setMsgId] = useState("");
  const [connectionUser, setConnectionUser] = useState("");
  const [userName, setUserName] = useState("");
  const [chatList, setChatList] = useState("");
  //************************Message List Start  ************************//
  const [isLoading, setIsLoading] = useState(true);
  const [messageList, setmessageList] = useState("");
  const [show, setShow] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [page, setPage] = useState(1);
  const [active, setActive] = useState();
  const [archiveActive, setArchiveActive] = useState();
  const [archiveMessage, setArchiveMessage] = useState(false);
  const getMessageList = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
    };
    axios
      .post(`Message/GetMessageList`, data)
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        const res = response.data.responseData;
        localStorage.setItem(
          "MessageChatId",
          response.data.responseData.chatID
        );

        setmessageList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getMessageList();
  }, []);

  //************************Message List End  ************************//

  //************************ Connection  List   ************************//
  const [ConnectionList, setConnectionList] = useState([]);
  const [searchconnectList, setSearchconnectList] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  // console.log('ConnectionList', ConnectionList);
  const ConnectionListAll = async (id) => {
    // console.log('calles');
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      pageNumber: page,
      pageSize: 10,
      type: "ConnectionList",
      search: searchUserName,
    };
    try {
      await axios.post("Connection/GetConnectionList", data).then((resData) => {
        console.log("resData", resData);
        // setConnectionList(resData.data.responseData.viewList);
        setIsLoading(false);

        if (searchUserName !== "") {
          // return newPosts;
          setConnectionList([]);
          setSearchconnectList(resData.data.responseData.viewList);
        } else {
          setSearchconnectList([]);
          // setConnectionList((prev) => [
          //   ...prev,
          //   ...resData.data.responseData.viewList,
          // ]);
          // const newPosts = resData.data.responseData.viewList;
          // setPostList((prev) => {
          //   const postSet = new Set(prev.map((post) => post.postId));
          //   const uniqueNewPosts = newPosts.filter(
          //     (post) => !postSet.has(post.postId)
          //   );
          //   return [...prev, ...uniqueNewPosts];
          // });
          setConnectionList((prev) => {
            const newViewList = resData?.data?.responseData?.viewList.filter(
              (item) =>
                !prev.some((prevItem) => prevItem.cnctnId === item.cnctnId)
            );
            return [...prev, ...newViewList];
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (show) {
      ConnectionListAll();
    }
  }, [show, searchUserName]);

  //************************Connection List ************************//

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        // setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  const handleSendMessage = (item) => {
    const userExistsInMessageList = messageList.some(
      (message) => message.chatInfo.toUserId === item.connectsWithUserUid
    );
    if (userExistsInMessageList) {
      // Perform the action if user exists in the message list
      // console.log("User exists in the message list");
      // Call the function you need to execute when user exists
    } else {
      setChatList("");
      setConnectionUser(item.connectsWithUserUid);
      setUserName(item.connectsWithUserName);
      setShow(false);
    }
  };

  //************************Archived List Start  ************************//
  // const [archivedList, setArchivedList] = useState([]);
  // const getArchivedList = () => {
  //   const data = {
  //     deviceType: "Web",
  //     sessionId: sessionId,
  //   };
  //   axios
  //     .post(`Message/GetArchivedMessageList`, data)
  //     .then((response) => {
  //       const res = response.data.responseData;
  //       setArchivedList(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // useEffect(() => {
  //   getArchivedList();
  // }, []);
  //************************Archived List End  ************************//

  //************************Message List Start  ************************//
  const [isLoadingChat, setIsLoadingChat] = useState(true);

  const [chatInfo, setChatInfo] = useState("");
  // console.log('chatList', chatList);
  const getChatList = () => {
    const data = {
      deviceType: "Web",
      sessionId: sessionId,
      // "msgID": msgId,
      toUserUid: UserId,
    };
    localStorage.setItem("toUserUid", data.toUserUid);
    axios
      .post(`Message/GetUserChat`, data)
      .then((response) => {
        // console.log(response);
        setIsLoadingChat(false);
        const res = response.data.responseData.chatList;
        //alert('test');
        var chatId = localStorage.getItem("DeletedChatId");
        setChatList(res);
        setChatInfo(response.data.responseData.messageInfo);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (UserId) {
      getChatList();
    }
  }, [UserId]);

  //************************Message List End  ************************//

  const [showClear, setShowClear] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [activeTab, setActiveTab] = useState(1);
  const [isShown, setIsShown] = useState(true);
  return (
    <UncontrolledDropdown
      tag=""
      className="dropdown-notification nav-item me-25"
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
        href="/"
        onClick={(e) => e.preventDefault()}
      >

        <TextsmsSharpIcon className="material-symbols-sharp" />

      </DropdownToggle>
      <DropdownMenu end tag="ul" className="dropdown-menu-media mt-0 notificationDropdown topmessages">
        {messageList.length === 0 ? <h4 className="notification-title mb-0 me-auto text-center">No Message</h4> :
          <>
            <li className="dropdown-menu-header">
              <DropdownItem className="d-flex" tag="div" header>
                <h4 className="notification-title mb-0 me-auto">Messages</h4>
              </DropdownItem>
            </li>

            {messageList &&
              messageList?.slice(0, 3)?.map((item, index) => {
                return (
                  <li className="dropdown-menu-header">
                    <DropdownItem className="topnotification d-flex align-items-center">
                      <div className="MessageListCont">
                        <Link
                          to={`/Message/${item?.chatInfo?.toUserUid}`}
                          onClick={() => { }}
                        >
                          <div
                            onClick={() => {
                              setActive(index);
                              // setToUserId(item.chatInfo.toUserUid)
                              setMsgId(item.chatInfo.msgID);
                            }}
                            className={`${isShown ? "d-block" : "d-md-block d-none"
                              } pointer`}
                          >
                            <div
                              key={index}
                              className={`${active === index ? "activeMsg" : ""
                                } message-cards d-flex align-items-center`}
                            >
                              {/* <div className="empty-msg-card ms-3 me-3"></div> */}
                              <div className="me-3 ms-2 topnotification">
                                <img
                                  src={
                                    item.chatInfo.toUserImage === ""
                                      ? people.DefaultProfile
                                      : item.chatInfo.toUserImage
                                  }
                                  alt=""
                                  width={40}
                                  height={40}
                                  style={{ borderRadius: "50px" }}
                                />
                              </div>
                              <div className="msg-content w-100 me-2">
                                <div className="name-time d-flex justify-content-between align-items-baseline">
                                  <div className="message-user-name darkmode f-12 f-600 d-flex">
                                    {item.chatInfo.toUserFullName}{" "}

                                    <div className="topMessagePin">
                                      {item?.isPined ? <img src={pin} width={20} /> : ""}{" "}
                                    </div>
                                  </div>
                                  <div className="message-time  darkmode f-10 f-400 ">
                                    {moment(item.lastMsgTime).fromNow()}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div
                                    className={`${item.lastMsgIsRead === false
                                      ? "fw-bolder f-14"
                                      : "fw-normal f-10"
                                      } messageText darkmode `}
                                  >
                                    {item.lastMsg}
                                  </div>
                                  <div className="d-flex">
                                    {item.isPined === true ? (
                                      <PushPinSharpIcon className="material-symbols-sharp" />

                                    ) : (
                                      ""
                                    )}

                                    <MessageInteraction
                                      showClear={showClear}
                                      setShowClear={setShowClear}
                                      showDelete={showDelete}
                                      setShowDelete={setShowDelete}
                                      chatId={item.chatID}
                                      isRead={item.lastMsgIsRead}
                                      isPined={item.isPined}
                                      getChatList={getChatList}
                                      archiveMessage={archiveMessage}
                                      msgId={item.chatInfo.msgID}
                                      UserId={item.chatInfo.toUserUid}
                                      // getArchivedList={getArchivedList}
                                      getMessageList={getMessageList}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </DropdownItem>
                  </li>
                );
              })}

            <li className="dropdown-menu-footer">
              <DropdownItem tag={Link} to="/Message/All">
                <button
                  type="submit"
                  className="wljPrimaryBtn d-flex align-items-center mt-3 noti-btn"
                >
                  View all messages
                </button>
              </DropdownItem>
            </li>
          </>}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
